const { default: NextI18Next } = require("next-i18next");

const NextI18NextInstance = new NextI18Next({
  lng: "de",
  defaultLanguage: "de",
  otherLanguages: ["en"],
  resources: {
    en: {
      common: require("../static/locales/en/common.json")
    },
    de: {
      common: require("../static/locales/de/common.json")
    }
  }
});

/* Optionally, export class methods as named exports */
module.exports = NextI18NextInstance;
